<template>
	<div
		v-if="title || elements.length"
		:id="$vnode.key"
		class="c-block-list-accordion-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<AccordionLayout v-bind="{ title, elements }">
			<template #title>
				<BaseH2 v-if="title" tag="h2" v-text="title" />
			</template>

			<template #header="{ item }">
				<BaseH4
					tag="h3"
					class="font-semibold"
					v-text="item.content.title"
				/>
			</template>

			<template #panel="{ item }">
				<Component
					:is="blockList"
					:grid-based="false"
					:block-list="item.content.accordionElements"
				/>
			</template>
		</AccordionLayout>
	</div>
</template>

<script>
import AccordionLayout from '~/components/shared/AccordionLayout';
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';

const blockLists = {
	main: () => import('~/components/main/BlockList'),
	subsite: () => import('~/components/subsite/BlockList'),
};

export default {
	name: 'BlockListAccordionCustom',

	components: {
		AccordionLayout,
		LongReadTarget,
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		elements: {
			type: Array,
			default: () => [],
		},
		includeInToc: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		blockList() {
			return blockLists[this.$store.getters.solution] || blockLists.main;
		},
	},
};
</script>
